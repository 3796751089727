import * as fb from '../../../api/firebase.js';
import { vuexfireMutations, firestoreAction } from 'vuexfire'

const actions = {
    callUpdateUserBadge(context, status) {
        return new Promise(async (resolve, reject) => {
            var updateBadges = fb.functions.httpsCallable("updateuserbadge");
            updateBadges({
                type: status.type,
                uid: status.uid,
                coachlistid: status.coachlistid
            })
            resolve()
        })
    },
    callCleanupBadges(context) {
        return new Promise(async (resolve, reject) => {
            var cleanBadges = fb.functions.httpsCallable("cleanupbadges");
            cleanBadges()
            resolve()
        })
    }
}

export default {
    actions
}