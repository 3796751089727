import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import contact from "./contact.module";
import program from "./program.module.js";
import notification from "./notification.module.js";
import awsivs from "./awsivs.module.js";
import livestream from "./livestream.module.js";
import faq from "./faq.module.js"
import header from "./header.module.js"
import blog from "./blog.module.js"
import coach from "./coach.module.js"
import user from "./user.module.js"
import corp from "./corporate.module.js"
import dashboard from "./dashboard.module.js"
import ml from "./ml.module.js";
import feed from "./feed.module.js";
import badge from "./badge.module.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    contact,
    program,
    notification,
    awsivs,
    livestream,
    faq,
    header,
    blog,
    coach,
    user,
    corp,
    dashboard,
    ml,
    feed,
    badge
  }
});
