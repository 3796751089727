import * as fb from '../../../api/firebase.js';
import * as tf from '@tensorflow/tfjs';
import { nanoid } from 'nanoid'

const state = {
    toxicScore: 0,
    spamScore: 0,
    nsfwScore: 1,
    showNsfw: false,
    recommendCoaches: [],
    aiChatHistory: [],
    chatData: []
}

const getters = {
    toxicScore: state => state.toxicScore,
    spamScore: state => state.spamScore,
    nsfwScore: state => state.nsfwScore,
    showNsfw: state => state.showNsfw,
    recommendCoaches: state => state.recommendCoaches,
    aiChatHistory: state => state.aiChatHistory,
    chatData: state => state.chatData
}

const actions = {
    resetMLPredict(context) {
        state.toxicScore = 0
        state.spamScore = 0
    },
    async calculateNsfwScore(context, payload) {
        return new Promise(async (resolve, reject) => {
            state.showNsfw = true
            const url = "https://ingomu-mlmodels.s3.amazonaws.com/nsfw/3.24.1/model.json"
            const model = await tf.loadLayersModel(url);
            const imageElement = new Image();
            imageElement.crossOrigin = "anonymous";
            imageElement.src = payload.imgSrc;
            imageElement.onload = function () {
                tf.tidy(() => {
                    let tensorImg = tf.browser.fromPixels(imageElement).resizeNearestNeighbor([224, 224]).toFloat().expandDims().div(255);
                    let x = model.predict(tensorImg)
                    const predictedValue = x.arraySync()[0];
                    let maxIndex = 0;
                    for (let i = 1; i < predictedValue.length; i++) {
                        if (predictedValue[i] > predictedValue[maxIndex]) {
                            maxIndex = i;
                        }
                    }
                    if (maxIndex == 0) {
                        state.nsfwScore = 0
                    } else {
                        state.nsfwScore = 1
                    }
                    state.showNsfw = false

                    if (payload.imgSrc.includes("image/png")) {
                        const imageToUpload = payload.imgSrc.replace("data:*/*;base64,", "").replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
                        fb.storage.child('ml-upload/' + nanoid() + '.png').putString(imageToUpload, 'base64').then((snapshot) => {
                        })
                    } else if (payload.imgSrc.includes("image/jpg") || payload.imgSrc.includes("image/jpeg")) {
                        const imageToUpload = payload.imgSrc.replace("data:*/*;base64,", "").replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "");
                        fb.storage.child('ml-upload/' + nanoid() + '.jpg').putString(imageToUpload, 'base64').then((snapshot) => {
                        })
                    }
                })
            }
        })
    },
    async calculateToxicScore(context, payload) {
        return new Promise(async (resolve, reject) => {
            const toxicresponse = await fetch("https://ingomu-mlmodels.s3.amazonaws.com/toxic/1.25.1/tokenizer_dictionary.json");
            const toxictokenizer = await toxicresponse.json();
            const toxicurl = "https://ingomu-mlmodels.s3.amazonaws.com/toxic/1.25.1/model.json"
            const toxicmodel = await tf.loadLayersModel(toxicurl);
            var text = payload.toxicText.toLowerCase();
            text = text.replace(/[!"#$%&()*+,-./:;<=>?@[\\\]^_`{|}~\t\n]/g, ' ')
            var split_text = text.split(' ');
            var tokens = [];
            split_text.forEach(element => {
                if (toxictokenizer[element] != undefined) {
                    tokens.push(toxictokenizer[element]);
                } else {
                    tokens.push(1)
                }
            });
            if (tokens.length > 100) { tokens = tokens.slice(0, 100) }
            let paddedArray = tokens.concat(Array(100 - tokens.length).fill(0))
            const prob = tf.tidy(() => {
                var x = paddedArray
                x = toxicmodel.predict(tf.tensor2d([x]))
                x = tf.mean(x);
                x = x.arraySync();
                return x
            })
            var calulatedProb = (prob * 100).toFixed(0)
            if (calulatedProb == 0) { calulatedProb = 1 }
            state.toxicScore = calulatedProb
        })
    },
    async calculateSpamScore(context, payload) {
        return new Promise(async (resolve, reject) => {
            const spamresponse = await fetch("https://ingomu-mlmodels.s3.amazonaws.com/spam/1.25.1/tokenizer_dictionary.json?v=1");
            const spamtokenizer = await spamresponse.json();
            const spamurl = "https://ingomu-mlmodels.s3.amazonaws.com/spam/1.25.1/model.json?v=1"
            const spammodel = await tf.loadLayersModel(spamurl);
            var text = payload.spamText.toLowerCase();
            text = text.replace(/[!"#$%&()*+,-./:;<=>?@[\\\]^_`{|}~\t\n]/g, ' ')
            var split_text = text.split(' ');
            var tokens = [];
            split_text.forEach(element => {
                if (spamtokenizer[element] != undefined) {
                    tokens.push(spamtokenizer[element]);
                } else {
                    tokens.push(1)
                }
            });
            if (tokens.length > 43) { tokens = tokens.slice(0, 43) }
            let paddedArray = tokens.concat(Array(43 - tokens.length).fill(0))
            const prob = tf.tidy(() => {
                var x = paddedArray
                x = spammodel.predict(tf.tensor2d([x]))
                x = tf.mean(x);
                x = x.arraySync();
                return x
            })
            var calulatedProb = (prob * 100).toFixed(0)
            if (calulatedProb == 0) { calulatedProb = 1 }
            state.spamScore = calulatedProb
        })
    },
    submitToxicText(context, form) {
        return new Promise(async (resolve, reject) => {
            let formData = {
                toxictext: form.toxicText,
                toxictype: form.toxicType
            }
            var addForReview = fb.functions.httpsCallable("addtoxicforreview");
            addForReview(formData)
            resolve()
        });
    },
    async getChatData(context, payload) {
        fb.mlCollection
            .where("type", "==", "chat")
            .limit(1)
            .get()
            .then((querySnapshot) => {
                let mlArray = []
                querySnapshot.forEach((doc) => {
                    let subMlArray = []
                    subMlArray = doc.data()
                    subMlArray['id'] = doc.id
                    mlArray.push(subMlArray)
                })
                if (mlArray.length > 0) {
                    state.chatData = mlArray
                }
            })
    },
    async deleteChatData(context, payload) {
        const startOfDay = new Date();
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date();
        endOfDay.setUTCHours(23, 59, 59, 999);

        let user = await fb.firebase.getCurrentUser()
        const messageRef = fb.usersCollection
        .doc(user.uid)
        .collection("generate")
        .where("status.completeTime", ">=", startOfDay)
        .where("status.completeTime", "<=", endOfDay)
        const messageSnap = await messageRef.get()
        if (!messageSnap.empty) {
            messageSnap.forEach((doc) => {
                doc.ref.delete();
            })
        }
    },
    async askAIQuestion(context, payload) {
        const app = fb.firebase.app();
        const functions = app.functions("us-central1");
        const coachRecommend = functions.httpsCallable("coachtextrecommendflow");
        coachRecommend(payload.message)
            .then((result) => {
                const data = result.data;
                let previousHistory = state.aiChatHistory;
                previousHistory.push(data);
                state.aiChatHistory = previousHistory;
            })
    }
}

export default {
    state,
    getters,
    actions
}
