<template>
    <!--begin::Card-->
    <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
            <h3 class="card-title">Company Signup</h3>
        </div>
        <!--begin::Form-->
        <form class="form" novalidate="novalidate" id="kt_save_changes_form">
            <div class="card-body">
                <h3 class="font-size-lg text-dark font-weight-bold mb-6">1. Company Info:</h3>
                <div class="mb-15">
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Name: <span class="text-danger">*</span></label>
                            <input type="text" name="name" ref="name" class="form-control"
                                placeholder="Enter the company name" v-model="form.name" />
                            <span class="form-text text-dark">Please enter the company name</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label for="exampleTextarea">Description</label>
                            <textarea class="form-control" name="description" ref="description" id="description"
                                rows="3" v-model="form.description"></textarea>
                            <span class="form-text text-dark">Use \n for new lines or breaks</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Video Link:</label>
                            <input type="text" name="videourl" ref="videourl" class="form-control"
                                placeholder="Enter the video url." v-model="form.video" />
                            <span class="form-text text-dark">Must be in MP4 format.</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Video Thumbnail:</label>
                            <input type="text" name="videothumbnail" ref="videothumbnail" class="form-control"
                                placeholder="Enter the video thumbnail url" v-model="form.videothumbnail" />
                            <span class="form-text text-dark">Image Size: 640X360</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <div class="image-input image-input-outline" id="kt_coachphoto">
                                <label class="mb-5">Company Image:</label>
                                <div class="image-input-wrapper"
                                    :style="{ backgroundImage: `url(${companyphoto})`, width: `546px`, height: `214px` }">
                                </div>
                                <label class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                    mt-35
                                                " data-action="change" data-toggle="tooltip" title=""
                                    data-original-title="Change company photo">
                                    <i class="fa fa-pen icon-sm text-dark"></i>
                                    <input type="file" name="companyphoto" accept=".png, .jpg, .jpeg"
                                        @change="onCompanyFileChange($event)" />
                                    <input type="hidden" name="profile_coachphoto_remove" />
                                </label>
                                <span class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                " data-action="cancel" data-toggle="tooltip"
                                    title="Cancel company photo">
                                    <i class="ki ki-bold-close icon-xs text-dark"></i>
                                </span>
                                <span class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                " data-action="remove" data-toggle="tooltip" title="Remove coach photo"
                                    @click="current_companyphoto = null; form.companyphoto = null;
                                    ">
                                    <i class="ki ki-bold-close icon-xs text-dark"></i>
                                </span>
                            </div>
                            <span class="form-text text-dark mt-5">Allowed file types: png, jpg,
                                jpeg.</span>
                        </div>
                    </div>
                </div>
                <h3 class="font-size-lg text-dark font-weight-bold mb-6">2. Payment:</h3>
                <div class="mb-15">
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Paypal Enabled:</label>
                            <b-form-select v-model="form.isPaid" :options="boolOptions" class="form-control"
                                name="isPaid" ref="isPaid"></b-form-select>
                            <span class="form-text text-dark">Entering yes will disable promo codes.</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Annual Price:</label>
                            <b-form-spinbutton id="price" name="price" ref="price" v-model="form.price" min="0"
                                max="1000"></b-form-spinbutton>
                            <span class="form-text text-dark">Enter the dollar amount.</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Semi-Annual Price:</label>
                            <b-form-spinbutton id="semi" name="semi" ref="semi" v-model="form.semi" min="0"
                                max="1000"></b-form-spinbutton>
                            <span class="form-text text-dark">Enter the dollar amount.</span>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <label>Best Value:</label>
                        <b-form-select v-model="form.bestvalue" :options="bestOptions" class="form-control"
                            name="bestvalue" ref="bestvalue"></b-form-select>
                    </div>
                </div>
                <h3 class="font-size-lg text-dark font-weight-bold mb-6">3. FAQ:</h3>
                <div class="mb-15">
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>FAQ 1 Question:</label>
                            <input type="text" name="question1" ref="question1" class="form-control"
                                placeholder="Enter the question" v-model="form.question1" />
                            <span class="form-text text-dark">Please enter the question</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label for="exampleTextarea">FAQ 1 Answer</label>
                            <textarea class="form-control" name="answer1" ref="answer1" id="answer1" rows="3"
                                v-model="form.answer1"></textarea>
                            <span class="form-text text-dark">Use \n for new lines or breaks</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <b-form-select v-model="form.select1" :options="onlineOptions" class="form-control"
                                name="select1" ref="select1"></b-form-select>
                        </div>
                    </div>
                    <hr class="my-15" />
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>FAQ 2 Question:</label>
                            <input type="text" name="question2" ref="question2" class="form-control"
                                placeholder="Enter the question" v-model="form.question2" />
                            <span class="form-text text-dark">Please enter the question</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label for="exampleTextarea">FAQ 2 Answer</label>
                            <textarea class="form-control" name="answer2" ref="answer2" id="answer2" rows="3"
                                v-model="form.answer2"></textarea>
                            <span class="form-text text-dark">Use \n for new lines or breaks</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <b-form-select v-model="form.select2" :options="onlineOptions" class="form-control"
                                name="select2" ref="select2"></b-form-select>
                        </div>
                    </div>
                    <hr class="my-15" />
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>FAQ 3 Question:</label>
                            <input type="text" name="question3" ref="question3" class="form-control"
                                placeholder="Enter the question" v-model="form.question3" />
                            <span class="form-text text-dark">Please enter the question</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label for="exampleTextarea">FAQ 3 Answer</label>
                            <textarea class="form-control" name="answer3" ref="answer3" id="answer3" rows="3"
                                v-model="form.answer3"></textarea>
                            <span class="form-text text-dark">Use \n for new lines or breaks</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <b-form-select v-model="form.select3" :options="onlineOptions" class="form-control"
                                name="select3" ref="select3"></b-form-select>
                        </div>
                    </div>
                    <hr class="my-15" />
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>FAQ 4 Question:</label>
                            <input type="text" name="question4" ref="question4" class="form-control"
                                placeholder="Enter the question" v-model="form.question4" />
                            <span class="form-text text-dark">Please enter the question</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label for="exampleTextarea">FAQ 4 Answer</label>
                            <textarea class="form-control" name="answer4" ref="answer4" id="answer4" rows="3"
                                v-model="form.answer4"></textarea>
                            <span class="form-text text-dark">Use \n for new lines or breaks</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <b-form-select v-model="form.select4" :options="onlineOptions" class="form-control"
                                name="select4" ref="select4"></b-form-select>
                        </div>
                    </div>
                    <hr class="my-15" />
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>FAQ 5 Question:</label>
                            <input type="text" name="question5" ref="question5" class="form-control"
                                placeholder="Enter the question" v-model="form.question5" />
                            <span class="form-text text-dark">Please enter the question</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label for="exampleTextarea">FAQ 5 Answer</label>
                            <textarea class="form-control" name="answer5" ref="answer5" id="answer5" rows="3"
                                v-model="form.answer5"></textarea>
                            <span class="form-text text-dark">Use \n for new lines or breaks</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <b-form-select v-model="form.select5" :options="onlineOptions" class="form-control"
                                name="select5" ref="select5"></b-form-select>
                        </div>
                    </div>
                    <hr class="my-15" />
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>FAQ 6 Question:</label>
                            <input type="text" name="question6" ref="question6" class="form-control"
                                placeholder="Enter the question" v-model="form.question6" />
                            <span class="form-text text-dark">Please enter the question</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label for="exampleTextarea">FAQ 6 Answer</label>
                            <textarea class="form-control" name="answer6" ref="answer6" id="answer6" rows="3"
                                v-model="form.answer6"></textarea>
                            <span class="form-text text-dark">Use \n for new lines or breaks</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <b-form-select v-model="form.select6" :options="onlineOptions" class="form-control"
                                name="select6" ref="select6"></b-form-select>
                        </div>
                    </div>
                    <hr class="my-15" />
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>FAQ 7 Question:</label>
                            <input type="text" name="question7" ref="question7" class="form-control"
                                placeholder="Enter the question" v-model="form.question7" />
                            <span class="form-text text-dark">Please enter the question</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label for="exampleTextarea">FAQ 7 Answer</label>
                            <textarea class="form-control" name="answer7" ref="answer7" id="answer7" rows="3"
                                v-model="form.answer7"></textarea>
                            <span class="form-text text-dark">Use \n for new lines or breaks</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <b-form-select v-model="form.select7" :options="onlineOptions" class="form-control"
                                name="select7" ref="select7"></b-form-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-lg-6">
                        <button ref="kt_save_changes" class="btn btn-primary mr-2">Save</button>
                    </div>
                </div>
            </div>
        </form>
        <!--end::Form-->
    </div>
    <!--end::Card-->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

export default {
    name: 'widget12',
    data() {
        return {
            form: {
                name: "",
                description: "",
                companyphoto: null,
                question1: "",
                answer1: "",
                select1: true,
                question2: "",
                answer2: "",
                select2: true,
                question3: "",
                answer3: "",
                select3: true,
                question4: "",
                answer4: "",
                select4: true,
                question5: "",
                answer5: "",
                select5: true,
                question6: "",
                answer6: "",
                select6: true,
                question7: "",
                answer7: "",
                select7: true,
                video: "",
                videothumbnail: "",
                corpid: this.$route.params.corpId,
                isPaid: false,
                price: 0,
                semi: 0,
                bestvalue: "annual"
            },
            default_companyphoto: "/media/logos/ingomu_logo-2.png",
            current_companyphoto: null,
            onlineOptions: [
                { value: true, text: 'Online' },
                { value: false, text: 'Offline' }
            ],
            boolOptions: [
                { value: true, text: 'True' },
                { value: false, text: 'False' }
            ],
            bestOptions: [
                { value: "annual", text: 'Annual' },
                { value: "semi", text: 'Semi-Annual' }
            ]
        };
    },
    mounted() {
        const changes_form = KTUtil.getById("kt_save_changes_form");

        this.fv = formValidation(changes_form, {
            fields: {
                name: {
                    validators: {
                        notEmpty: {
                            message: "Company name is required",
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });

        let self = this;
        this.fv.on("core.form.valid", () => {
            // set spinner to submit button
            const submitButton = this.$refs["kt_save_changes"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.$store.dispatch("createNewCompany", this.form)
                .then(
                    function (value) {
                        Swal.fire({
                            title: "",
                            text: "It should take a few minutes to show up in the company list.",
                            icon: "success",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                self.$router.push({ name: 'corporate' })
                            }
                        })
                    },
                    function (error) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: error,
                            icon: "error",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        });
                    });
        });

        this.fv.on("core.form.invalid", () => {
            Swal.fire({
                title: "",
                text: "Please, provide correct data!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: true,
            });
        });


    },
    computed: {
        companyphoto() {
            return this.current_companyphoto == null || this.current_companyphoto == "undefined"
                ? this.default_companyphoto
                : this.current_companyphoto;
        }
    },
    methods: {
        onCompanyFileChange(e) {
            const file = e.target.files[0];

            if (typeof FileReader === "function") {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.current_companyphoto = event.target.result;
                    this.form.companyphoto = event.target.result;
                };

                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        }
    }
}
</script>

<style scoped>
.mt-35 {
    margin-top: 35px !important;
}
</style>
